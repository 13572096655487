<template>
  <div class="flex flex-col items-center">
    <div class="w-full space-y-2 xl:w-1/2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
<!--      @submit.prevent="testingisUpdating ? update(payment) : store({ ...payment, ...payment.amount * 100 })"-->

      <form
        class="space-y-4"
        @submit.prevent="storePayment"
        @keydown="removeError($event.target.id)">
        <h3 class="my-4 text-2xl">
          {{ isUpdating ? 'Actualizar' : 'Registrar' }} pago
        </h3>

        <a-select
            :source="[
            { label: 'Efectivo', value: 1 },
            { label: '100% Banco', value: 2 },
            { label: 'Transferencia Bancaria', value: 3 },
            { label: 'Transferencia de Curso', value: 4 },
            { label: 'Zelle', value: 5 },
          ]"
            label="Tipo de pago"
            required
            class="mb-4"
            :error="error('type', { attribute: 'tipo de pago' })"
            v-model="payment.type"/>

        <a-input
            :disabled="payment.type === 1"
            :required="payment.type !== 1"
            label="Factura"
            class="mb-4"
            id="receipt_number"
            :error="error('receipt_number')"
            v-model="payment.receipt_number" />

        <a-input
          label="Fecha"
          class="mb-4"
          required
          id="date"
          type="date"
          :error="error('date')"
          v-model="payment.date" />

        <a-input
          label="Monto (USD)"
          required
          class="mb-4"
          id="amount"
          type="number"
          :error="error('amount')"
          v-model.number="payment.amount" />

        <a-select
          :source="students.map(el => ({ label: `${el.first_name} ${el.last_name}`, value: el.id }))"
          :error="error('student_id')"
          class="mb-4"
          required
          label="Estudiante"
          :loading="loadingStudents"
          v-model="payment.student_id"/>

        <a-select
              label="Período"
              class="mb-4"
              required
              :source="periods.map(el => ({ label: `${el.year}-${el.number}`, value: el.id }))"
              :error="error('period_id')"
              :loading="loadingPeriods"
              v-model="payment.period_id" />

        <div class="flex space-x-2">
          <a-button mode="primary" type="submit" :loading="loading">
            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
          </a-button>

          <a-button outlined :to="{ name: 'payments' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    payment: {
      date: null,
      receipt_number: null,
      amount: null, // este valor se guarda en centimos. 500000 => 500.00
      type: null,   // ver tipos de pago mas abajo
      student_id: null, // pertenece a un estudiante
      period_id: null,  // creado en un periodo
    },
    isUpdating: false
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      payments: state => state.payments.pagination.data,
      currentPaymentOnState: state => state.payments.course,
      loading: state => state.payments.loading,
      periods: state => state.periods.all,
      students: state => state.students.all,
      loadingPeriods: state => state.periods.loading,
      loadingStudents: state => state.students.loading,
    })
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    ...mapActions({
      fetchCourse: 'payments/fetch',
      fetchPeriods: 'periods/index',
      fetchStudents: 'students/index',
      store: 'payments/store',
      update: 'payments/update',
      removeError: 'removeError',
    }),
    handleFileSelection(event) {
      this.course.scheduled_content_resource = event.target.files[0]
      this.removeError(event.target.id)
    },
    storePayment () {
      this.payment.amount = this.payment.amount * 100
      if (this.isUpdating) {
        this.update(this.payment)
      } else {
        this.store({ ...this.payment })
      }
    }
  },
  watch: {
    currentPaymentOnState(val) {
      this.course = {...val}
    }
  },
  created() {
    const id = this.$route.query.update
    this.setNavbarTitle(['Listado de materias', 'Crear materia'])
    this.fetchPeriods({ limit: 0 })
    this.fetchStudents({ limit: 0 })
    if (id) {
      this.isUpdating = true

      const found = this.payments.find(course => course.id === parseInt(id))
      if (found) {
        this.payment = {...found}
        this.payment.amount = parseInt((this.payment.amount / 100).toFixed(0))
        this.setNavbarTitle(['Listado de pagos', found.title, 'Editar pago'])
      } else {
        this.fetchCourse(id)
      }
    }
  }
}
</script>
